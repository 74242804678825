import axios from "axios";
import { LoginRequestBody } from "../types/typesLoginRequest.interface";
import { ResponseWrapper } from "../types/typesResponseWrapper.interface";
import { TokenContainer } from "../types/typesTokenContainer.interface";
import Cookies from "js-cookie";

export const logInUser = (
  username?: string,
  password?: string
): Promise<ResponseWrapper> => {
  const requestBody = <LoginRequestBody>{};
  if (username && password) {
    requestBody.username = username;
    requestBody.password = password;
  } else {
    requestBody.grantType = "refresh_token";
    requestBody.refreshToken = Cookies.get("fadRefresh");
  }
  return axios
    .post(`${process.env.VUE_APP_AUTH_API_URL}oauth2/token`, requestBody)
    .then((val) => {
      return {
        success: <Boolean>true,
        data: <TokenContainer>val.data,
      };
    })
    .catch((err) => {
      return {
        success: false,
        data: err.response.status,
      };
    });
};
